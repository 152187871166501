// Disable linter for this part as it isn't aware of platformClient loaded in index.html
/* eslint-disable */
const client = platformClient.ApiClient.instance;
//client.setEnvironment("mypurecloud.com.au");
//client.setEnvironment("mypurecloud.com");
client.setPersistSettings(true);

// GENESYS CLOUD IMPLICIT GRANT
const clientId = process.env.VUE_APP_CLIENT_ID;
// const redirectUri = process.env.VUE_APP_REDIRECT_URL; // Not used. Generated Dynamically and saved as this.redirectUri

// GENESYS CLOUD API
const usersApi = new platformClient.UsersApi();
const conversationsApi = new platformClient.ConversationsApi();
const notificationsApi = new platformClient.NotificationsApi();
const presenceApi = new platformClient.PresenceApi();
const routingApi = new platformClient.RoutingApi();
const voicemailApi = new platformClient.VoicemailApi();

// CONSTANTS
const daysOldToSearch = 30;
const pageSize = 100;
const refreshDataAfterApiCallDelayMsec = 5000;

import { RoutingApi } from "purecloud-platform-client-v2";
// EXPORT
import VueJsonToCsv from "vue-json-to-csv";

// VUE BEGINS
export default {
  components: {
    VueJsonToCsv,
  },
  name: "App",
  data: () => ({
    redirectUrl: null,
    pcEnvironment: "mypurecloud.com.au",
    cxlogiq: {
      appName: "email-samurai",
      appDisplayName: null,
      logging: true,
    },
    licensing: {
      mode: null,
      expires: null,
      contact: null,
      licenseDisplayString: null,
    },
    licensed: false,
    urlParams: null,
    user: {
      avatar: null,
      id: null,
      name: null,
      division: null,
      roles: [],
    },
    organization: {
      id: null,
      name: null,
      domain: null,
    },
    // VUETIFY UI PROPERTIES
    selected: [2], //TODO: remove maybe?
    conversation: null,
    searchEmailTable: "",
    selectedQueues: [],
    loadingEmails: false,
    headers: [
      {
        text: "From Address",
        align: "start",
        sortable: true,
        value: "fromAddress",
      },
      { text: "To Address", sortable: true, value: "toAddress" },
      { text: "Subject", sortable: true, value: "subject" },
      {
        text: "Conversation Start",
        sortable: true,
        value: "conversationStart",
      },
      // { text: "Latest Message Date", sortable: true, value: "conversationStart" },
      {
        text: "Latest Message Date",
        sortable: true,
        value: "latestMessageDate",
      },
      { text: "Current Queue", sortable: true, value: "currentQueue" },
      // { text: "In Progress At", sortable: true, value: "inProgressAt" },
      // { text: "Skills", sortable: true, value: "skills" },
      // { text: "Type", sortable: true, value: "emailType" },
      { text: "Purpose", sortable: true, value: "inProgressPurpose" },
      { text: "Status", sortable: true, value: "inProgressAt" },
      { text: "Conversation ID", sortable: true, value: "id" },
    ],
    // EMAIL SAMURAI DATA BEGINS
    queues: [],
    users: [],
    skills: [],
    userSpecificQueues: [],
    parkSpecificQueues: [],
    conversationsRaw: [],
    conversationsPretty: [],
    conversationsPrettyFiltered: [],
    ui: {
      showDialogAlert: false,
      disableDialogCloseButton: false,
      bulkDisconnectConfirm: false,
      singleDisconnectConfirm: false,
      bulkTrasnferConfirm: false,
      showEmailsAtAgents: true,
      bulkDisconnectCancelConfirm: false,
      showDialogCancelButton: false,
      toggleStateSelectAll: false,
      toggleQueueFilter: false,      
    },
    checkedEmails: [],
    bulkActionCounter: 0,
    transfer: {
      selectedQueue: null,
      selectedUser: null,
      bulkSelectedQueue: null,
    },
    filter: {
      selectedQueue: null,
      searchFromAddress: null,
      searchToAddress: null,
      searchSubject: null,
    },
    search: {
      dateFromIso: null,
      dateToIso: null,
      datePicker: [],
    },
    preview: {
      showAutoResponses: false,
      conversationId: null,
      fromAddress: null,
      toAddress: null,
      messages: [],
      inProgressPurpose: null,
    },
    previewAttributes: {},
    uiData: {
      drawer: true,
    },
  }), // END DATA
  watch: {
    conversationsRaw: {
      // Look Inside the Array
      deep: true,
      handler(conversationsArray) {
        this.formatConversationData(conversationsArray);
        this.selectedQueues = [ ... this.selectedQueues];
        
      },
    },
    selectedQueues: {
      handler(selectedQueues) {
        console.log(selectedQueues);
        const conversationsPrettyFiltered = this.conversationsPretty.filter(
          (conversation) => selectedQueues.includes(conversation.currentQueue)
        );
        // console.log(conversationsPrettyFiltered);
        this.conversationsPrettyFiltered = conversationsPrettyFiltered;
        
        // Andrew working here...
        // this.$nextTick(() => {
        //   if (this.$refs.autoComplete) {
        //     // stop curson jumping to start of input
            
        //     //this.$refs.autoComplete.$refs.menu.selectedIndex = 4;
        //     this.$refs.autoComplete.setMenuIndex(10);
        //     console.log(this.$refs.autoComplete);
        //   }
        // });
      },
    },
  }, // END WATCH
  computed: {
    icon() {
      return 'mdi-checkbox-blank-outline'
    },
    filteredQueuesToggle() {
      if (this.ui.toggleQueueFilter) {
        return this.conversationsPrettyFiltered;
      } else {
        return this.conversationsPretty;
      }
    },
  },
  created() {
    this.log("######### " + this.cxlogiq.appName + " is Starting! #########");
    // ENV VARIABLES
    this.log("* clientId: " + clientId);

    // GENERATE REDIRECT URI
    let fullUrl = window.location.href;
    let fullPath = this.$route.fullPath;

    let baseURL = fullUrl;
    if (fullPath != "/") {
      // Otherwise it strips / from https://www... to make it http:/www...
      baseURL = fullUrl.replace(fullPath, "");
    }
    this.redirectUrl = baseURL;

    this.log("* Full URL: " + fullUrl);
    this.log("* Full Path: " + fullPath);
    this.log("* baseURL: " + baseURL);

    // URLPARAMS
    console.log(this.$route.query);
    this.urlParams = JSON.stringify(this.$route.query);

    this.log("* URL Parameters: " + this.urlParams);

    // DEFINE CLIENT
    this.log("Setting Genesys Cloud Environment");
    console.log(platformClient.PureCloudRegionHosts); // remove
    const client = platformClient.ApiClient.instance;

    console.log(this.pcEnvironment);
    console.log(this.$route.query);

    if (this.$route.query.environment) {
      this.pcEnvironment = this.$route.query.environment;
      localStorage.setItem("pcEnvironment", this.pcEnvironment);
    }

    // set environment using the environment variable on pre-redirect
    client.setEnvironment(
      localStorage.getItem("pcEnvironment") || this.pcEnvironment
    );

    // INITIATE LOGIN
    this.loginImplicitGrant();
  },
  filters: {
    formatISODate(inputISO) {
      return new Date(inputISO).toLocaleString("en-GB");
    },
  },
  methods: {
    // LOGGING
    log(message) {
      if (this.cxlogiq.logging) {
        console.log("[CXLOGiQ] " + message);
      }
    },
    // STARTUP
    loginImplicitGrant() {
      this.log("PureCloud ImplicitGrant requested");
      this.log("* Using redirectURL: " + this.redirectUrl);
      client
        .loginImplicitGrant(clientId, this.redirectUrl)
        .then((data) => {
          this.log("PureCloud ImplicitGrant success");
          this.getUsersMe();
        })
        .catch((err) => {
          this.log("PureCloud ImplicitGrant failure");
          console.log(err);
        });
    },
    getUsersMe() {
      this.log("PureCloud getUsersMe requested");
      const opts = {
        expand: ["authorization", "organization"],
      };
      usersApi
        .getUsersMe(opts)
        .then((data) => {
          this.log("PureCloud getUsersMe success");
          console.log(data);

          if (data.images) {
            this.user.avatar = data.images[0].imageUri;
          }
          this.user.id = data.id;

          this.user.name = data.name;
          this.user.roles = data.authorization.roles;
          this.user.division = data.division.id;
          this.organization.id = data.organization.id;
          this.organization.name = data.organization.name;
          this.organization.domain = data.organization.domain;
          this.phoneHome();
        })
        .catch((err) => {
          this.log("PureCloud getUsersMe failure");
          console.log(err);
        });
    },
    // PRODUCT VALIDATION
    async phoneHome() {
      // REFERENCE FOR VUE OBJECT USED AFTER LICENSE RESPONSE
      const that = this;
      // BEGINS
      this.log("phoneHome requested");
      try {
        // SETUP
        const phoneHomeUrl = process.env.VUE_APP_PHONEHOME_URL;
        const phoneHomeKey = process.env.VUE_APP_PHONEHOME_KEY;
        const phoneHomeRequestUrl = phoneHomeUrl + "?code=" + phoneHomeKey;
        const phoneHomeRequestBody = {
          orgId: this.organization.id,
          applicationName: this.cxlogiq.appName,
        };
        // ACTION
        let licenseRequest = new XMLHttpRequest();
        licenseRequest.open("POST", phoneHomeRequestUrl, true);
        licenseRequest.setRequestHeader("content-type", "application/json");
        licenseRequest.onload = function() {
          that.log("phoneHome success");
          let jsonResponse = JSON.parse(this.response);
          // TO DO - THE APP DISPLAY NAME IS HERE!!!! JSON.response.appDisplayName or whatever
          that.licensing.mode = jsonResponse.license;
          that.licensing.expires = jsonResponse.expires;
          that.licensing.contact = jsonResponse.contact;
          that.cxlogiq.appDisplayName = jsonResponse.appDisplayName;
          that.checkLicenseState();
        };
        licenseRequest.send(JSON.stringify(phoneHomeRequestBody));
      } catch (err) {
        // handles 400,403,404, etc. but NOT 50x
        this.log("phoneHome failure");
        console.log(err);
        this.appNotLicensed();
      }
    },
    checkLicenseState() {
      this.log("checkLicenseState requested");
      try {
        // CHEcK FULLY LICENSED
        if (this.licensing.mode == "licensed") {
          this.log("checkLicenseState success - Licensed");
          this.log("LICENSED!");
          this.licensed = true;
        }
        // CHECK TRIAL
        const dateNow = new Date();
        const dateExpires = new Date(this.licensing.expires);
        const remainingTimeMsec = dateExpires - dateNow;
        const remainingTimeDays = Math.ceil(
          remainingTimeMsec / (1000 * 60 * 60 * 24)
        );
        if (this.licensing.mode == "trial" && remainingTimeMsec > 0) {
          this.log("checkLicenseState success - Trial Mode");
          this.log("TRIAL OK!");
          this.licensed = true;
          this.licensing.licenseDisplayString =
            "Trial Mode. " + remainingTimeDays + " days remaining...";
        }
        // ACTIONS
        if (this.licensed) {
          this.appMainStart();
        } else {
          this.log("checkLicenseState success - Unlicensed/Expired Trial");
          this.licensing.licenseDisplayString =
            "Unlicensed. Please contact sales...";
          this.appNotLicensed();
        }
      } catch (err) {
        this.log("checkLicenseState failed");
        console.log(err);
        this.appNotLicensed();
      }
    },
    appNotLicensed() {
      this.log(
        "######### " +
          this.cxlogiq.appName +
          " is NOT LICENSED FOR OPERATION! #########"
      );
    },
    // APP STARTS HERE IF USER lOGGED IN SUCCESS & PRODUCT VALIDATED AS LICENSED/TRIAL MODE
    /* NOTES:
            1. this.licensed should be used as Master boolean to enable/disable Display componants
        */
    appMainStart() {
      this.log(
        "######### " +
          this.cxlogiq.appName +
          " is Running! in " +
          this.licensing.mode +
          " mode #########"
      );
      // DEFINE SEARCH INTERVALS
      let d = new Date();
      let dateFuture = d.setDate(d.getDate());
      let dateOld = d.setDate(d.getDate() - daysOldToSearch);
      this.search.dateToIso = new Date(dateFuture).toISOString();
      this.search.dateFromIso = new Date(dateOld).toISOString();
      // ACTION!
      let pageNumber = 1;
      this.getUsers(pageNumber);
      this.getSkills();
      this.getRoutingQueues(pageNumber);
      this.searchForEmails();
    },
    // POPULATE USER/QUEUE ARRAYS
    // TODO: make switch for division Q search or not
    getRoutingQueues(pageNumber) {
      this.log("getRoutingQueues requested!");
      const opts = {
        pageSize: 500, // Number | Page size
        pageNumber: pageNumber, // Number | Page number
        sortBy: "name", // String | Sort by
        //divisionId: [this.user.division], // [String] | Division ID(s)
      };
      routingApi
        .getRoutingQueues(opts)
        .then((data) => {
          console.log(data);
          this.log("getRoutingQueues success!");
          let queuesArray = data.entities;
          queuesArray.forEach((queue) => {
            let tempQueueName = queue.name;
            tempQueueName = tempQueueName.toUpperCase();
            // ALL QUEUES
            this.queues.push({
              id: queue.id,
              name: queue.name,
            });
            // PARK QUEUES
            if (tempQueueName.includes("USER")) {
              this.userSpecificQueues.push({
                id: queue.id,
                name: queue.name,
              });
            }
            // USER QUEUES
            if (tempQueueName.includes("PARK")) {
              this.parkSpecificQueues.push({
                id: queue.id,
                name: queue.name,
              });
            }
          });
          // RECURSIVE CALL TO GET ALL QUEUES
          if (data.pageCount > pageNumber) {
            pageNumber++;
            this.getRoutingQueues(pageNumber);
          }
        })
        .catch((err) => {
          this.log("getRoutingQueues failure!");
          console.log(err);
        });
    },
    
    getUsers(pageNumber) {
      this.log("getUsers requested!");
      const opts = {
        pageSize: 100, // Number | Page size
        pageNumber: pageNumber, // Number | Page number
        divisionId: [this.user.division], // [String] | Division ID(s)
      };
      usersApi
        .getUsers(opts)
        // console.log(data)
        .then((data) => {
          this.log(`getUsers success! Page ${pageNumber}`);
          let usersArray = data.entities;
          usersArray.forEach((user) => {
            this.users.push({
              id: user.id,
              name: user.name,
            });
          });
          // RECURSIVE CALL TO GET ALL USERS
          if (data.pageCount > pageNumber) {
            pageNumber++;
            this.getUsers(pageNumber);
          }
        })
        .catch((err) => {
          this.log("getUsers failure!");
          console.log(err);
        });
    },
    getSkills() {
      this.log("getSkills requested!");
      const opts = {
        pageSize: 250, // Number | Page size
        pageNumber: 1, // Number | Page number
        // 'name': "name_example", // String | Filter for results that start with this value
        // 'id': ["id_example"] // [String] | id
      };
      routingApi
        .getRoutingSkills(opts)
        .then((data) => {
          this.log("getSkills success!");
          console.log(data.entities.length);
          console.log(data.entities);
          this.skills = data.entities;
        })
        .catch((err) => {
          this.log("getSkills failure!");
          console.error(err);
        });
    },
    // SEARCH FOR SKILL NAME
    getSkillName(skillGuid) {
      // this.log('gettingSkillName for: '+skillGuid)
      let skillReturn = "";
      let skillsArray = this.skills;
      skillsArray.forEach((skillObj) => {
        if (skillObj.id == skillGuid) {
          skillReturn = skillObj.name;
        }
      });
      return skillReturn;
    },
    // SEARCH FOR EMAILS
    defineQueryBody(pageNumber) {
      // DEFINE FILTERS
      let no_filter_body = {
        interval: this.search.dateFromIso + "/" + this.search.dateToIso,
        order: "asc",
        orderBy: "conversationStart",
        paging: {
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        segmentFilters: [
          {
            type: "or",
            predicates: [
              {
                type: "dimension",
                dimension: "mediaType",
                operator: "matches",
                value: "email",
              },
            ],
          },
        ],
        conversationFilters: [
          {
            type: "or",
            predicates: [
              {
                type: "dimension",
                dimension: "conversationEnd",
                operator: "notExists",
                value: null,
              },
            ],
          },
        ],
      };
      let from_filter_body = {
        interval: this.search.dateFromIso + "/" + this.search.dateToIso,
        order: "asc",
        orderBy: "conversationStart",
        paging: {
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        segmentFilters: [
          {
            type: "and",
            predicates: [
              {
                type: "dimension",
                dimension: "mediaType",
                operator: "matches",
                value: "email",
              },
              {
                type: "dimension",
                dimension: "addressFrom",
                operator: "matches",
                value: this.filter.searchFromAddress,
              },
            ],
          },
        ],
        conversationFilters: [
          {
            type: "or",
            predicates: [
              {
                type: "dimension",
                dimension: "conversationEnd",
                operator: "notExists",
                value: null,
              },
            ],
          },
        ],
      };
      let to_filter_body = {
        interval: this.search.dateFromIso + "/" + this.search.dateToIso,
        order: "asc",
        orderBy: "conversationStart",
        paging: {
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        segmentFilters: [
          {
            type: "and",
            predicates: [
              {
                type: "dimension",
                dimension: "mediaType",
                operator: "matches",
                value: "email",
              },
              {
                type: "dimension",
                dimension: "addressTo",
                operator: "matches",
                value: this.filter.searchToAddress,
              },
            ],
          },
        ],
        conversationFilters: [
          {
            type: "or",
            predicates: [
              {
                type: "dimension",
                dimension: "conversationEnd",
                operator: "notExists",
                value: null,
              },
            ],
          },
        ],
      };
      let from_and_to_filter_body = {
        interval: this.search.dateFromIso + "/" + this.search.dateToIso,
        order: "asc",
        orderBy: "conversationStart",
        paging: {
          pageSize: pageSize,
          pageNumber: pageNumber,
        },
        segmentFilters: [
          {
            type: "and",
            predicates: [
              {
                type: "dimension",
                dimension: "mediaType",
                operator: "matches",
                value: "email",
              },
              {
                type: "dimension",
                dimension: "addressTo",
                operator: "matches",
                value: this.filter.searchToAddress,
              },
              {
                type: "dimension",
                dimension: "addressFrom",
                operator: "matches",
                value: this.filter.searchFromAddress,
              },
            ],
          },
        ],
        conversationFilters: [
          {
            type: "or",
            predicates: [
              {
                type: "dimension",
                dimension: "conversationEnd",
                operator: "notExists",
                value: null,
              },
            ],
          },
        ],
      };
      // DECIDE WHICH ONE TO RETURN
      let body = no_filter_body;
      if (
        (this.filter.searchFromAddress != null) &
        (this.filter.searchToAddress == null)
      ) {
        this.log("* Using from_filter_body");
        body = from_filter_body;
      }
      if ((this.filter.from == null) & (this.filter.searchToAddress != null)) {
        this.log("* Using to_filter_body");
        body = to_filter_body;
      }
      if (
        (this.filter.searchFromAddress != null) &
        (this.filter.searchToAddress != null)
      ) {
        this.log("* Using from_and_to_filter_body");
        body = from_and_to_filter_body;
      }
      // console.log(body)
      return body;
    },
    searchForEmails() {
      // this.loadingEmails = true;
      // RESET RESULTS
      this.conversationsRaw = [];

      // NOTE: ADDING TO conversationsRaw TRIGGERS HANDLER formatConversationData(conversation)
      // START
      let totalHits = 0;
      this.log("postAnalyticsConversationsDetailsQuery Page 1 requested!");
      let body = this.defineQueryBody(1);
      // console.log('*************************')
      // console.log(body)
      conversationsApi
        .postAnalyticsConversationsDetailsQuery(body)
        .then((data) => {
          this.log("postAnalyticsConversationsDetailsQuery Page 1 success!");
          console.log(
            "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"
          );
          console.log(data);
          console.log(
            "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"
          );
          totalHits = data.totalHits;
          if (totalHits > 0) {
            let conversationsArray = data.conversations;
            // console.log(conversationsArray)
            conversationsArray.forEach((conversation) => {
              this.conversationsRaw.push(conversation);
            });
            // WORK OUT HOW MANY MORE PAGES
            this.log("* totalHits : " + totalHits);
            let totalPages = Math.ceil(totalHits / pageSize);
            this.log("* totalPages: " + totalPages);
            // GET THE REST OF THE PAGES
            if (totalPages > 1) {
              for (let p = 2; p <= totalPages; p++) {
                this.log(
                  "postAnalyticsConversationsDetailsQuery Page " +
                    p +
                    " requested!"
                );
                body = this.defineQueryBody(p);
                conversationsApi
                  .postAnalyticsConversationsDetailsQuery(body)
                  .then((data) => {
                    this.log(
                      "postAnalyticsConversationsDetailsQuery Page " +
                        p +
                        " success!"
                    );
                    console.log(
                      "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"
                    );
                    console.log(data);
                    console.log(
                      "$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$"
                    );
                    let tempConversationsArray = data.conversations;
                    tempConversationsArray.forEach((conversation) => {
                      this.conversationsRaw.push(conversation);
                    });
                  })
                  .catch((err) => {
                    this.log(
                      "postAnalyticsConversationsDetailsQuery Page " +
                        p +
                        " failure!"
                    );
                    console.log(err);
                  });
              }
            }
          } else {
            this.log("No Emails are currently Queued!");
          }
          this.loadingEmails = false;
        })
        .catch((err) => {
          this.log("postAnalyticsConversationsDetailsQuery failure!");
          console.log(err);
        });
    },

    defaultSearch() {
      let d = new Date();
      let dateFuture = d.setDate(d.getDate() + 1);
      let dateOld = d.setDate(d.getDate() - daysOldToSearch);
      this.search.dateToIso = new Date(dateFuture).toISOString();
      this.search.dateFromIso = new Date(dateOld).toISOString();

      this.log("Searching for EMails:");
      this.log("* From: " + this.search.dateFromIso);
      this.log("*   To: " + this.search.dateToIso);
      this.searchForEmails();
    },
    extendSearchXDays(extendedSearchDays) {
      // RE-DEFINE SEARCH INTERVALS
      let d = new Date();

      // Subtract ExtendedSearchDays from dateEnd ONLY
      // dateStart is calcuatled from this value
      let dateEnd = d.setDate(d.getDate() + 1 - extendedSearchDays);
      let dateStart = d.setDate(d.getDate() - daysOldToSearch);
      this.search.dateToIso = new Date(dateEnd).toISOString();
      this.search.dateFromIso = new Date(dateStart).toISOString();

      this.log("Searching for EMails:");
      this.log("* From: " + this.search.dateFromIso);
      this.log("*   To: " + this.search.dateToIso);

      this.searchForEmails();
    },

    // MAKE IT PRETTY
    formatConversationData(conversationsArray) {
      this.log("formatConversationData requested");
      console.log(conversationsArray);
      this.conversationsPretty = [];

      conversationsArray.forEach((conversation) => {
        // console.log(conversation)
        // FIND IT
        let fromAddress = "";
        let fromName = "";
        let toAddress = "";
        let subject = "";
        let inProgressAt = "";
        let currentQueue = "";
        let inProgressPurpose = "";
        let latestMessageDateTime = "";
        let requestedRoutingSkillsList = [];
        let skillNamesList = "";
        let emailType = "";

        // FIND IT
        let participantsArray = conversation.participants;
        participantsArray.forEach((participant) => {
          // console.log(participant)

          // STARTED AS INBOUND
          if (
            (participant.purpose == "external" ||
              participant.purpose == "customer") &&
            conversation.originatingDirection == "inbound"
          ) {
            // CUSTOMER
            fromAddress = participant.sessions[0].addressFrom;
            fromName = participant.participantName;
            toAddress = participant.sessions[0].addressTo;
            emailType = participant.sessions[0].provider; // Added for Type column in email table

            let segmentsArray = participant.sessions[0].segments;
            segmentsArray.forEach((segment) => {
              if (segment.subject != null) {
                subject = segment.subject;
              }
            });
            // subject = participant.sessions[0].segments[0].subject
          }

          // STARTED AS OUTBOUND
          if (
            participant.purpose == "agent" &&
            conversation.originatingDirection == "outbound"
          ) {
            // CUSTOMER
            fromAddress = participant.sessions[0].addressFrom;
            fromName = participant.participantName;
            toAddress = participant.sessions[0].addressTo;

            let segmentsArray = participant.sessions[0].segments;
            segmentsArray.forEach((segment) => {
              if (segment.subject != null) {
                subject = segment.subject;
              }
            });
            // subject = participant.sessions[0].segments[0].subject
          }

          if (participant.purpose == "acd") {
            currentQueue = participant.participantName;
            // Was hardcoded [0] - Changed to length-1 14/12/2021
            let participantSessionsArray =
              participant.sessions[participant.sessions.length - 1];
            latestMessageDateTime =
              participantSessionsArray.segments[
                participantSessionsArray.segments.length - 1
              ].segmentStart;

            requestedRoutingSkillsList =
              participantSessionsArray.segments[
                participantSessionsArray.segments.length - 1
              ].requestedRoutingSkillIds;
            // GET SKILL NAMES IF THEY EXIST

            if (
              requestedRoutingSkillsList &&
              requestedRoutingSkillsList.length > 0
            ) {
              skillNamesList = [];
              requestedRoutingSkillsList.forEach((skillId) => {
                skillNamesList =
                  skillNamesList + this.getSkillName(skillId) + "; ";
              });
            }
          }
        });

        // FIND PURPOSE & IN PROGRESS AT AKA Participant Name
        let tempPurpose = "";
        let tempParticipantName = "";
        // set tempSegmentStart to 1980 - arbirary old date
        let tempSegmentStart = new Date(1980, 1, 11);

        try {
          participantsArray.forEach((participant) => {
            // Look for the last segment of the last session of all participants
            // Added additional filtering to only look for segments that are of type 'interact' to filter out agent wrapup segments which will be newer than the new ACD segment
            const segmentData =
              participant.sessions[participant.sessions.length - 1].segments[
                participant.sessions[participant.sessions.length - 1].segments
                  .length - 1
              ];
            //console.log(`Pariticpant Purpose: ${purpose} Segment Start: ${segmentData.segmentStart}`)
            //console.log(new Date(segmentData.segmentStart) > new Date(tempSegmentStart))

            // Checking if customer has sent another email while agent still handling prior
            if (
              participant.purpose == "agent" &&
              segmentData.segmentType == "interact"
            ) {
              tempParticipantName = participant.participantName;
              tempPurpose = participant.purpose;
              throw new Error("email still at agent, stopping iteration");
            }

            // Checking if the email is parked at an agent
            if (
              participant.purpose == "agent" &&
              segmentData.segmentType == "parked"
            ) {
              tempParticipantName = participant.participantName;
              tempPurpose = 'parked';
              throw new Error("email still at agent, stopping iteration");
            }

            if (
              new Date(segmentData.segmentStart) > new Date(tempSegmentStart)
            ) {
              if (
                segmentData.segmentType == "interact" ||
                segmentData.segmentType == "hold" ||
                segmentData.segmentType == "transmitting"
              ) {
                tempSegmentStart = new Date(segmentData.segmentStart);
                tempPurpose = participant.purpose;
                tempParticipantName = participant.participantName;
                //tempPurpose = 'agent'
                // if (segmentData.disconnectType == 'client') { todo: use additional segmentData to further refine state at agent e.g. ACW
                //   tempPurpose = 'acw'
                // }
              }
            }

            

          });
        } catch (error) {
          console.log("found agent, stopping iteration");
        }

        inProgressAt = tempParticipantName;
        inProgressPurpose = tempPurpose;

        if (
          // NO QUEUE/SUBJECT FILTER
          (this.filter.selectedQueue == null &&
            this.filter.searchSubject == null) ||
          // QUEUE FILTER
          (this.filter.selectedQueue != null &&
            this.filter.selectedQueue == currentQueue &&
            this.filter.searchSubject == null) ||
          // SUBJECT FILTER
          (this.filter.searchSubject != null &&
            subject.includes(this.filter.searchSubject) &&
            this.filter.selectedQueue == null) ||
          // QUEUE AND SUBJECT FILTER
          (this.filter.searchSubject != null &&
            subject.includes(this.filter.searchSubject) &&
            this.filter.selectedQueue != null &&
            this.filter.selectedQueue == currentQueue)
        ) {
          // FILTERS OUT EMAIL IN PROGRESS AT AN AGENT
          if (
            (!this.ui.showEmailsAtAgents && inProgressPurpose != "agent") ||
            this.ui.showEmailsAtAgents
          ) {
            // if (inProgressPurpose != "external") {
            // commenting out above as GCloud Q Activty Detail view shows agents drafting outbound emails and want to keep consistent across ES and GC
            // external means agent writing new outbound eail but not yet sent
            this.conversationsPretty.push({
              id: conversation.conversationId,
              //conversationStart: this.formatDateforDisplay(conversation.conversationStart),
              // conversationStart: conversation.conversationStart,
              conversationStart: new Date(
                conversation.conversationStart
              ).toLocaleString("en-GB"),
              // conversationStart: new Date(latestMessageDateTime).toLocaleString("en-GB"),
              // conversationStart: this.formatDateforDisplay(latestMessageDateTime),
              latestMessageDate: this.formatDateforDisplay(
                conversation.conversationId,
                latestMessageDateTime
              ),
              fromAddress: fromAddress,
              toAddress: toAddress,
              subject: subject,
              inProgressAt: inProgressAt,
              currentQueue: currentQueue,
              inProgressPurpose: inProgressPurpose,
              skills: skillNamesList,
              emailType: emailType,
            });
            // }
          }
        }
      });
    },
    // DCATE FORMATING
    formatDateforDisplay(conversationId, inputDate) {
      let returnDate = "";
      try {
        if (inputDate != null && inputDate != "") {
          let tempDate = new Date(inputDate).toLocaleString("en-GB");
          let splitString = tempDate.split(",");

          let dateStr = splitString[0]; // FORMAT: dd/mm/yyyy
          let splitDateString = dateStr.split("/");
          let ddStr = splitDateString[0];
          let mmStr = splitDateString[1];
          let yyyyStr = splitDateString[2];

          let timeStr = splitString[1]; // FORMAT: HH:MM:SS
          let timeSplitString = timeStr.split(":");
          let HHstr = timeSplitString[0];
          let MMstr = timeSplitString[1];
          // let SSstr = timeSplitString[2]

          returnDate =
            yyyyStr + "-" + mmStr + "-" + ddStr + " " + HHstr + ":" + MMstr;
        } else {
          console.log(
            "* No date to format for converstationId: " + conversationId
          );
        }
      } catch (err) {
        console.log(err);
      }
      return returnDate;
    },
    // PREVIEW STUFF
    clearPreviewData() {
      this.preview.messages = [];
      (this.preview.conversationId = null),
        (this.preview.fromAddress = null),
        (this.preview.toAddress = null),
        (this.preview.subject = null);
    },
    previewEmail(conversationId, inProgressPurpose) {
      this.preview.inProgressPurpose = inProgressPurpose;

      // Added for SFDC POC to get Conversations Attributes
      this.getConversationAttributes(conversationId);

      // Carry on with original code before adding SFDC type

      this.log("getConversationsEmailMessages requested: " + conversationId);
      this.clearPreviewData();
      conversationsApi
        .getConversationsEmailMessages(conversationId)
        .then((dataEmail) => {
          this.log("getConversationsEmailMessages success!");
          console.log(dataEmail)
          let tempLocalMessages = dataEmail.entities;
          this.previewEmailMessages(conversationId, tempLocalMessages);
        })
        .catch((err) => {
          this.log("getConversationsEmailMessages error!");
          console.log(err);
        });
    },

    async previewEmailMessages(conversationId, tempLocalMessages) {
      let maxIdLength = 0;
      //tempLocalMessages.forEach((message) => {
      for (const message of tempLocalMessages) {
        // console.log('* messageId = '+message.id)
        let tempMessageId = message.id;

        // ARE AUTO-RESPONSES INCLUDED IN DISPLAY
        if (this.preview.showAutoResponses) {
          maxIdLength = 100;
        } else {
          maxIdLength = 100; // increased from 40 as id length has no bearing on auto-responses - pull this apart later
        }
        // FIND AND DISPLAY MESSAGES
        if (tempMessageId.length < maxIdLength) {
          this.log("getConversationsEmailMessage requested: " + message.id);
          await conversationsApi
            .getConversationsEmailMessage(conversationId, message.id)
            .then((dataMessage) => {
              this.log("getConversationsEmailMessage success!"); // add message id to log
              // console.log(dataMessage)
              this.preview.conversationId = conversationId;
              this.preview.fromAddress =
                message.from.name + " (" + message.from.email + "); ";
              this.preview.toAddress = "";
              let tempToList = message.to;
              tempToList.forEach((to) => {
                this.preview.toAddress =
                  this.preview.toAddress + to.name + " (" + to.email + "); ";
              });
              this.preview.subject = message.subject;
              //this.preview.messages.push(dataMessage.textBody)
              //this.preview.messages.push(dataMessage.htmlBody);

              // This kinda works but can't iterate through attachments and assumes them all to be images

              // if (dataMessage.htmlBody && dataMessage.attachments.length > 0) {
              //   dataMessage.htmlBody = dataMessage.htmlBody.replaceAll(
              //     /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
              //     `<img width="50%" src=${dataMessage.attachments[0].contentUri}>`
              //   )
              // }

              // This removes inline images from the htmlBody
              if (dataMessage.htmlBody && dataMessage.attachments.length > 0) {
                dataMessage.htmlBody = dataMessage.htmlBody.replaceAll(
                  /<img[^>"']*((("[^"]*")|('[^']*'))[^"'>]*)*>/g,
                  ``
                );
              }

              let tempMessage = {
                textBody: dataMessage.textBody,
                htmlBody: dataMessage.htmlBody,
                //htmlBody: tempHtmlBodySplit,
                //htmlBody: htmlNoImage,
                attachments: dataMessage.attachments,
              };
              this.preview.messages.push(tempMessage);
            })
            .catch((err) => {
              this.log("getConversationsEmailMessage failure!");
              console.log(err);
            });
        }
      }
    },
    getConversationAttributes(conversationId) {
      console.log(`Getting conversation Attributes for ${conversationId}`);

      conversationsApi
        .getConversation(conversationId)
        .then((data) => {
          console.log(
            `Found the following atrributes for conversation ${conversationId}`
          );
          console.log(data.participants[0].attributes);
          this.previewAttributes = data.participants[0].attributes;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // UI STUFF
    async toggleSelectAll(rows) {
      const items = rows.items;
      let emailsAtAgentCount = 0;

      await items.forEach((item) => {
        console.log(item);
        if (item.inProgressPurpose == "agent" || item.inProgressPurpose == 'parked') {
          emailsAtAgentCount++;
        }
      });

      console.log(`Emails at agent: ${emailsAtAgentCount}`);
      console.log(`Number of selected emails is: ${this.checkedEmails.length}`);

      if (this.ui.toggleStateSelectAll == false) {
        this.checkedEmails = [];

        rows.items.forEach((item) => {
          if (item.inProgressPurpose != "agent" && item.inProgressPurpose != 'parked') {
            this.checkedEmails.push(item);
          }
        });
        this.ui.toggleStateSelectAll = true;
        console.log("Toggle state is set to true");
      } else if (this.ui.toggleStateSelectAll == true) {
        this.checkedEmails = [];
        this.ui.toggleStateSelectAll = false;
        console.log("Toggle state is set to false");
      }

      console.log(this.checkedEmails);
    },
    rowSelected(item, row) {
      // AUTOSELECT EMAIL -ITEM AND ROW GIVE ACCESS TO THE TABLE ROW DATA
      console.log(item);
      console.log(row);
      if (item.inProgressPurpose != "agent" && item.inProgressPurpose != 'parked') {
        row.select(true);
      }

      // PREVIEW EMAIL
      const conversationId = item.id;
      this.previewEmail(conversationId, item.inProgressPurpose);
    },
    rowChecked(conversationId) {
      // ADD EMAIL TO CHECKED EMAILS ARRAY
      if (this.checkedEmails.includes(conversationId)) {
        this.checkedEmails.pop(conversationId);
      } else {
        this.checkedEmails.push(conversationId);
      }
    },
    // selectAllQueues(){
    //   console.log('Selecting all queues')
    //   this.selectedQueues = this.queues.map((queue) => queue.name)
    // },
    deselectQueues(item) {
      console.log(item)
      //this.selectedQueues.pop(item.name)
      this.selectedQueues = this.selectedQueues.filter(queue => queue != item.name)
    },
    deselectAllQueues() {
      console.log("Deselecting all queues");
      this.selectedQueues = [];
    },
    // FILTER BUTTONS
    clearAllfilters() {
      this.log("clearAllFilters requested");
      this.clearQueueFilter();
      this.filter.selectedQueue = null;
      this.filter.searchFromAddress = null;
      this.filter.searchToAddress = null;
      this.filter.searchSubject = null;
      this.filterEmails();
    },
    filterEmails() {
      // this method still needs renanming to getConversations or something
      this.loadingEmails = true;
      setTimeout(() => {
        this.ui.disableDialogCloseButton = false;
        this.ui.bulkDisconnectCancelConfirm = false;
        this.ui.disableDialogCloseButton = false;
        this.bulkActionCounter = 0;
        this.checkedEmails = [];
        this.ui.showDialogAlert = false;
        this.defaultSearch()
      }, refreshDataAfterApiCallDelayMsec);
    },
    // TOGGLE AUTO RESPONSE DISPLaY
    toggleShowAutoResponses() {
      if (this.preview.showAutoResponses) {
        this.preview.showAutoResponses = false;
      } else {
        this.preview.showAutoResponses = true;
      }
      // REFRESH CURRENTLY SELECTED CONVERSATION
      if (this.preview.conversationId != null) {
        this.previewEmail(this.preview.conversationId);
      }
    },
    // TRANSFER STUFF
    transferAction(transferBody) {
      this.log(
        "getConversationsEmail requested: " + this.preview.conversationId
      );
      conversationsApi
        .getConversationsEmail(this.preview.conversationId)
        .then((data) => {
          this.log("getConversationsEmail success");
          // console.log(data)
          // ID ACD PARTICIPANT
          let acdParticipantGuid = null;
          let participantsArray = data.participants;
          participantsArray.forEach((participant) => {
            if (participant.purpose == "acd") {
              acdParticipantGuid = participant.id;
            }
          });
          this.log("* ACD ParticipantId: " + acdParticipantGuid);
          // TRANSFER
          this.log("postConversationsEmailParticipantReplace requested");
          conversationsApi
            .postConversationsEmailParticipantReplace(
              this.preview.conversationId,
              acdParticipantGuid,
              transferBody
            )
            .then((response) => {
              this.log("postConversationsEmailParticipantReplace success");
              // console.log(response);
              this.preview = {
                showAutoResponses: false,
                conversationId: null,
                fromAddress: null,
                toAddress: null,
                messages: [],
              };
              this.filterEmails();
            })
            .catch((err) => {
              this.log("postConversationsEmailParticipantReplace failure");
              console.log(err);
            });
        })
        .catch((err) => {
          this.log("getConversationsEmail failure!");
          console.log(err);
        });
    },
    bulkTransferAction(transferBody, conversationId) {
      this.log("getConversationsEmail requested: " + conversationId);
      conversationsApi
        .getConversationsEmail(conversationId)
        .then((data) => {
          this.log("getConversationsEmail success");
          // console.log(data)
          // ID ACD PARTICIPANT
          let acdParticipantGuid = null;
          let participantsArray = data.participants;
          participantsArray.forEach((participant) => {
            if (participant.purpose == "acd") {
              acdParticipantGuid = participant.id;
            }
          });
          this.log("* ACD ParticipantId: " + acdParticipantGuid);
          // TRANSFER
          this.log("postConversationsEmailParticipantReplace requested");
          conversationsApi
            .postConversationsEmailParticipantReplace(
              conversationId,
              acdParticipantGuid,
              transferBody
            )
            .then((response) => {
              this.log("postConversationsEmailParticipantReplace success");
              // console.log(response);
              // DELAY OTHERWISE API RETURNS OLD VALUES
              // this.bulkActionCounter = i + 1;

              // if (this.bulkActionCounter == this.checkedEmails.length) {
              //   setTimeout(() => {
              //     // REFRESH THE DISPLAY
              //     this.filterEmails();
              //   }, refreshDataAfterApiCallDelayMsec);
              // }
              // if (this.bulkActionCounter >= this.checkedEmails.length) {
              //   setTimeout(() => {
              //     // REFRESH THE DISPLAY
              //     //this.filterEmails();
              //   }, refreshDataAfterApiCallDelayMsec);
              // }
            })
            .catch((err) => {
              this.log("postConversationsEmailParticipantReplace failure");
              console.log(err);
            });
        })
        .catch((err) => {
          this.log("getConversationsEmail failure!");
          console.log(err);
        });
    },
    transferToQueue() {
      if (
        this.preview.conversationId != null &&
        this.transfer.selectedQueue != null
      ) {
        this.log(
          "### TRANSFER " +
            this.preview.conversationId +
            " TO QUEUE " +
            this.transfer.selectedQueue +
            " REQUESTED! ###"
        );
      }
      let body = {
        queueId: this.transfer.selectedQueue,
      };
      this.transferAction(body);
    },
    transferToUser() {
      if (
        this.preview.conversationId != null &&
        this.transfer.selectedUser != null
      ) {
        this.log(
          "### TRANSFER " +
            this.preview.conversationId +
            " TO USER " +
            this.transfer.selectedUser +
            " REQUESTED! ###"
        );
      }
      let body = {
        transferType: "Attended",
        userId: this.transfer.selectedUser,
      };
      this.transferAction(body);
    },
    transferToSelf() {
      if (this.preview.conversationId != null && this.user.id != null) {
        this.log(
          "### TRANSFER " +
            this.preview.conversationId +
            " TO USER " +
            this.user.id +
            " REQUESTED! ###"
        );
      }
      let body = {
        transferType: "Attended",
        userId: this.user.id,
      };
      this.transferAction(body);
    },
    bulkTransferToQueue() {
      if (
        this.checkedEmails.length > 0 &&
        this.transfer.bulkSelectedQueue != null
      ) {
        this.ui.showDialogCancelButton = true;
        this.ui.disableDialogCloseButton = true;
        this.bulkActionCounter = 0;
        this.log("### BULK TRANSFER REQUESTED! ###");
        // let transferBody = {
        //   queueId: this.transfer.bulkSelectedQueue,
        // };
        // let tempArray = this.checkedEmails;

        // tempArray.forEach((conversation, i) => {
        //   setTimeout(() => {
        //     this.bulkActionCounter = i + 1;
        //     this.bulkTransferAction(transferBody, conversation.id);
        //     if (this.bulkActionCounter == this.checkedEmails.length) {
        //       this.filterEmails();
        //     }
        //   }, i * 500);
        // });

        this.bulkTransferLoop();
      } else {
        this.log("Bulk list is EMPTY");
      }
    },
    async bulkTransferLoop() {
      for (let i = 0; i < this.checkedEmails.length; i++) {
        console.log(i);

        if (this.ui.bulkDisconnectCancelConfirm == true) {
          this.bulkDisconnectComplete();
          break;
        }

        await this.waitForMe(1500);
        // transfer action goes here
        let conversationId = this.checkedEmails[i].id;
        let transferBody = { queueId: this.transfer.bulkSelectedQueue };
        this.bulkTransferAction(transferBody, conversationId);

        if (i == this.checkedEmails.length - 1) {
          this.bulkDisconnectComplete();
        }
        this.bulkActionCounter = i + 1;
      }
    },
    // DISCONNECT STUFF
    disconnectAction() {
      this.ui.disableDialogCloseButton = true;
      if (this.preview.conversationId != null) {
        this.log(
          "### DISCONNECT " + this.preview.conversationId + " REQUESTED! ###"
        );
        this.log("getConversation requested: " + this.preview.conversationId);
        conversationsApi
          .getConversation(this.preview.conversationId)
          .then((data) => {
            this.log("getConversation success");
            console.log(data);
            let participantsArray = data.participants;
            const participantId =
              participantsArray[participantsArray.length - 1].id;
            let emailsArray =
              participantsArray[participantsArray.length - 1].emails;
            const communicationId = emailsArray[emailsArray.length - 1].id;
            this.log(" * communicationId: ");
            this.log(" * participantId: ");
            if (communicationId != null && participantId != null) {
              let body = {
                state: "disconnected",
              };
              this.log(
                "patchConversationsCallParticipantCommunication: " +
                  this.preview.conversationId
              );
              conversationsApi
                .patchConversationsCallParticipantCommunication(
                  this.preview.conversationId,
                  participantId,
                  communicationId,
                  body
                )
                .then((data) => {
                  this.log(
                    "patchConversationsCallParticipantCommunication success"
                  );
                  // Reset preview object so UI updates and does not display last disconnected email
                  this.preview = {
                    showAutoResponses: false,
                    conversationId: null,
                    fromAddress: null,
                    toAddress: null,
                    messages: [],
                  };
                  // console.log(data);
                  // DELAY OTHERWISE API RETURNS OLD VALUES
                  setTimeout(() => {
                    // REFRESH THE DISPLAY
                    this.filterEmails();
                  }, refreshDataAfterApiCallDelayMsec);
                })
                .catch((err) => {
                  this.log(
                    "patchConversationsCallParticipantCommunication failure"
                  );
                  console.error(err);
                });
            }
          })
          .catch((err) => {
            this.log("getConversation failure");
            console.log(err);
          });
        this.ui.showSingleDisconnectConfirm = false;
      }
    },
    bulkDisconnectAction(conversationId) {
      this.log("### DISCONNECT " + conversationId + " REQUESTED! ###");
      this.log("getConversation requested: " + conversationId);
      conversationsApi
        .getConversation(conversationId)
        .then((data) => {
          this.log("getConversation success");
          //console.log(data)
          let participantsArray = data.participants;
          const participantId =
            participantsArray[participantsArray.length - 1].id;
          let emailsArray =
            participantsArray[participantsArray.length - 1].emails;
          const communicationId = emailsArray[emailsArray.length - 1].id;
          this.log(" * communicationId: ");
          this.log(" * participantId: ");
          if (communicationId != null && participantId != null) {
            let body = {
              state: "disconnected",
            };
            this.log(
              "patchConversationsCallParticipantCommunication: " +
                conversationId
            );
            conversationsApi
              .patchConversationsCallParticipantCommunication(
                conversationId,
                participantId,
                communicationId,
                body
              )
              .then((data) => {
                this.log(
                  "patchConversationsCallParticipantCommunication success"
                );
                // console.log(data);
                // DELAY OTHERWISE API RETURNS OLD VALUES
                // if (this.bulkActionCounter >= this.checkedEmails.length) {
                //   setTimeout(() => {
                //     // REFRESH THE DISPLAY
                //     this.filterEmails();
                //   }, refreshDataAfterApiCallDelayMsec);
                // }
              })
              .catch((err) => {
                this.log(
                  "patchConversationsCallParticipantCommunication failure"
                );
                console.error(err);
              });
          }
        })
        .catch((err) => {
          this.log("getConversation failure");
          console.log(err);
        });
    },
    bulkDisconnectConfirmed() {
      if (this.checkedEmails.length > 0) {
        this.ui.showDialogCancelButton = true;
        this.ui.disableDialogCloseButton = true;
        this.bulkActionCounter = 0;
        this.log("### BULK DISCONNECT REQUESTED! ###");
        // let tempArray = this.checkedEmails;

        // tempArray.forEach((conversation, i) => {
        //   setTimeout(() => {
        //     if (this.ui.bulkDisconnectCancelConfirm == true) {
        //       this.ui.showDialogAlert = false
        //       this.filterEmails()
        //       return false;
        //     }
        //     this.bulkActionCounter = i + 1;
        //     this.bulkDisconnectAction(conversation.id);
        //     if (this.bulkActionCounter == this.checkedEmails.length) {
        //       this.filterEmails();
        //     }
        //   }, i * 2000);
        // });

        this.bulkDisconnectLoop();
      } else {
        this.log("Bulk list is EMPTY");
      }
      this.ui.showBulkDisconnectConfirm = false;
    },
    waitForMe(ms) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve("");
        }, ms);
      });
    },
    async bulkDisconnectLoop() {
      for (let i = 0; i < this.checkedEmails.length; ++i) {
        console.log(i);

        if (this.ui.bulkDisconnectCancelConfirm == true) {
          this.bulkDisconnectComplete();
          break;
        }

        await this.waitForMe(1500);
        let conversationId = this.checkedEmails[i].id;
        this.bulkDisconnectAction(conversationId);

        if (i == this.checkedEmails.length - 1) {
          this.bulkDisconnectComplete();
        }
        this.bulkActionCounter = i + 1;
      }
    },
    bulkDisconnectComplete() {
      this.ui.showDialogCancelButton = false;
      this.filterEmails();
      this.log("* BULK DISCONNECT COMPLETE");
    },
    bulkDisconnectCancel() {
      this.log("* CANCEL BULK DISCONNECT");
      this.ui.bulkDisconnectCancelConfirm = true;
    },
    bulkDisconnectRequest() {
      this.log("* BULK DISCONNECT ARE YOU SURE");
      this.ui.showBulkDisconnectConfirm = true;
    },
    // STUFF THAT COULD BE DONE BETTER?
    clearQueueFilter() {
      this.log("*** DO SOMETHING TO CLEAR THE QUEUE FILTER IN HERE? ***");
    },
    // UI METHODS
    clickRow(conversationId) {
      console.log(conversationId);
    },
    showDialog(dialogAction) {
      this.ui.showDialogAlert = true;

      if (dialogAction == "bulkDisconnect") {
        this.ui.singleDisconnectConfirm = false;
        this.ui.bulkDisconnectConfirm = true;
        this.ui.bulkTrasnferConfirm = false;
      }

      if (dialogAction == "singleDisconnect") {
        this.ui.singleDisconnectConfirm = true;
        this.ui.bulkDisconnectConfirm = false;
        this.ui.bulkTrasnferConfirm = false;
      }

      if (dialogAction == "bulkTransfer") {
        this.ui.singleDisconnectConfirm = false;
        this.ui.bulkDisconnectConfirm = false;
        this.ui.bulkTrasnferConfirm = true;
      }
    },
    launchConversation(conversationId) {
      console.log("launchConversation: " + conversationId);
      window.open(
        `https://apps.${this.pcEnvironment}/directory/#/engage/admin/interactions/${conversationId}`,
        "_blank"
      );
    },
  },
};
